import { Box, Divider, Stack, Typography } from '@mui/material';
import { DateService } from '@services/DateService';
import colors from '@styles/colors.module.scss';
import { Tag } from '@components/Tag';
import { TicketResponse } from '@api/admin/adminGeneratedApi';
import { getHumanName } from '@area/tickets/utils/getHumanName';
import { Routes } from '@navigation/routes';

export type TicketMainInfoProps = {
  data: TicketResponse;
};

export const TicketMainInfo = ({ data }: TicketMainInfoProps) => {
  const { resolved, createdAt, resolvedDate, human } = data;
  console.log(human);

  return (
    <Box border="1px solid" borderColor={colors.grey} borderRadius={1} p="20px">
      <Stack
        sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}
        divider={<Divider flexItem orientation={'vertical'} />}>
        <Box>
          <Typography mb="12px">Статус</Typography>
          <Tag
            label={resolved ? 'Решено' : 'Новое'}
            sx={{
              background: resolved ? colors.success : colors.lightRed,
              color: resolved ? colors.white : colors.black,
            }}
          />
        </Box>
        <Box>
          <Typography mb="12px">Дата создания</Typography>
          <Typography fontWeight={400}>{DateService.getLocalDate(createdAt) || '-'}</Typography>
        </Box>
        <Box>
          <Typography mb="12px">Дата отправки ответа</Typography>
          <Typography fontWeight={400}>{resolvedDate ? DateService.getLocalDate(resolvedDate) : '-'}</Typography>
        </Box>
        <Box>
          <Typography mb="12px">Статус отправки ответа</Typography>
          <Typography fontWeight={400}>{resolved ? 'Отправлено' : '-'}</Typography>
        </Box>
        <Box>
          <Typography mb="12px">Кем создано</Typography>
          <Typography fontWeight={400}>{getHumanName(data) || '-'}</Typography>
          {human?.id && (
            <a href={`/${Routes.students}/${human?.id}`} target="_blank" rel="noreferrer">
              Открыть
            </a>
          )}
        </Box>
        <Box>
          <Typography mb="12px">ВУЗ</Typography>
          <Typography fontWeight={400}>{data?.human?.student?.educationalOrganization?.nameShort || '-'}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};
